import Seo from 'src/components/seo/Seo'
import PrivacyPolicy from 'src/components/servicos/ServicesPage/PoliticaPrivacidade'

function Page() {
  return (
    <>
      <Seo language="pt-br" title="Política de privacidade | Decathlon" />
      <PrivacyPolicy />
    </>
  )
}

export default Page
